module.exports = {
  'title': 'Create New Account',
  'title_invitation': 'Activate Account',
  'subtitle': 'Become a member and get special benefits',
  'placeholder_firstname': 'First Name',
  'placeholder_lastname': 'Last Name',
  'placeholder_email': 'Email address',
  'placeholder_password': 'Create Password',
  'placeholder_nationality': 'Nationality',
  'placeholder_tc': 'I have read and accept the ',
  'placeholder_tc_link': 'terms and conditions',
  'placeholder_subscribe': 'I would like to receive updates and information on new services and promotions',
  'button_label': 'SUBMIT',
  'title_modal_email_exist': 'Email already existed',
  'description_modal_email_exist': 'The email address you entered is already registered as our member. Do you want to login instead ?',
  'btn_redirect_login': 'LOGIN',
  'btn_change_email': 'CHANGE EMAIL',
  'placeholder_selectbox': 'Please a select',
  'title_alert_message': 'Invitation',
  'btn_label_signin': 'SUBMIT'
}